
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';
import { VmLauncher } from '@/components/data-operations/runs-collections';

@Component({
	components: { VmLauncherCollectionListing: VmLauncher.collectionListing },
})
export default class VmLauncherRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {}
